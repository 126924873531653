//.btn {
//  border-radius: 1.25rem;
//  padding-left: 1.25rem;
//  padding-right: 1.25rem;
//
//  i {
//    position: relative;
//    top: 1px;
//  }
//}
//
//.btn-lg {
//  border-radius: 2rem;
//}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }
}

//@each $color, $value in $theme-colors {
//  .btn-outline-#{$color} {
//    @include button-outline-variant($value);
//  }
//}

.btn-primary {
  color: white;
}