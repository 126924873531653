.selected-color {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: white no-repeat center center;
  background-size: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
}

.selected-color__header {
  height: $selected-color-header-height;
  background-color: white;
  text-align: right;

  button {
    width: $selected-color-header-height;
    height: $selected-color-header-height;
    background: none;
    border: none;
    box-shadow: none;
    outline: none;

    img {
      width: $selected-color-header-height * .35;
      height: $selected-color-header-height * .35;
      opacity: .7;
    }
  }
}

.selected-color__footer {
  width: 100%;
  height: $selected-color-header-height * 1.4;
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 1;

  h4 {
    text-align: center;
    margin: 0;
    color: #717171;
  }

  h5 {
    text-align: center;
    margin: 0;
    color: #717171;
  }
}