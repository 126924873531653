.landing-footer {
  background-color: #646266;
  color: white;
  padding: 3rem 0 2rem 0;

  a {
    color: white;
  }

  h5 {
    font-size: $font-size-base;
    font-weight: $font-weight-semi-bold;
  }

  .nav {
    a {
      padding: .15rem 0;
    }
  }
}

.footer-newsletter {

}

.footer-newsletter__title {

}

.footer-newsletter__subtitle {
  font-size: $font-size-sm;
  color: rgba(255, 255, 255, 0.5);
}

.footer-newsletter__form {
  input {
    background-color: rgba(255, 255, 255, 0.13);
    border: none;
    border-radius: 0;

    &::placeholder {
      color: #A1A1A1;
    }

    &:focus,
    &:active {
      background-color: rgba(255, 255, 255, 0.13);
      outline: none;
      box-shadow: none;
      color: white;
    }
  }

  button {
    background-color: $green;
    border-color: $green;
    border-radius: $input-height;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.footer-social-links {
  a {
    width: 33%;
    display: inline-block;

    i {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}

.footer-logo-and-copy {
  img {
    height: 25px;
    width: auto;
    opacity: .5;
  }

  p {
    padding-top: 5px;
    font-size: $font-size-sm * 0.75;
    opacity: .5;
  }
}