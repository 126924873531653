.certified-box {
  background: $specs-card-background;
  text-align: center;
  background: #F9F8F5;
  box-shadow: 2px 2px 4px -2px rgba(0,0,0,0.50);
}

.certified-box__title {
  font-size: $h5-font-size;
  padding: 1rem 1rem 0 1rem;
  color: $green;
  font-weight: $font-weight-semi-bold;
  margin: 0;
}

.certified-box__subtitle {
  padding: 1rem;
  text-align: justify;
  color: $specs-card-color;
}

.specs-icon {
  text-align: center;
  color: #88888A;
}

.specs-icon__image {
  width: 25px;
  height: 25px;
  margin: 0 auto .5rem auto;
}

.specs-icon__name {
  font-size: $font-size-sm * 0.85;
  text-transform: uppercase;
}