.colour-card {
  border: 1px solid #D6D6D6;
  cursor: pointer;
  @include transition(opacity ease-out 100ms);

  &:hover {
    opacity: .8;
  }
}

.colour-card__image {
  height: 0;
  padding-bottom: 80%;
  background-color: #f1f1f1;
  background: no-repeat center center;
  background-size: cover;
  position: relative;

  i.fa {
    position: absolute;
    right: 5px;
    bottom: 5px;
    color: #343434;
  }
}

.colour-card__description {
  text-align: center;
  padding: .3rem 0;

  h6 {
    text-transform: uppercase;
    font-size: $font-size-sm * 0.85;
    color: #717171;
    margin: 0;
  }

  p {
    font-size: $font-size-sm * 0.85;
    margin: 0;
    color: #717171;
  }
}