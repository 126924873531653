@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-Bold.woff2') format('woff2'),
        url('GillSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-SemiBold.woff2') format('woff2'),
        url('GillSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-Light.woff2') format('woff2'),
        url('GillSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-SemiBoldItalic.woff2') format('woff2'),
        url('GillSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-LightItalic.woff2') format('woff2'),
        url('GillSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans.woff2') format('woff2'),
        url('GillSans.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-UltraBold.woff2') format('woff2'),
        url('GillSans-UltraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-Italic.woff2') format('woff2'),
        url('GillSans-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gill Sans';
    src: url('GillSans-BoldItalic.woff2') format('woff2'),
        url('GillSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

