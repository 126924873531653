.form-success-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 102%;
  border: 1px solid #25AE88;
  background-color: #ECECEC;
  border-radius: 10px;

  > div {
    height: 100%;
  }
}

.form-success-layer-info {
  padding: 0 2rem;

  h1 {
    margin-bottom: 0;
  }

  img {
    width: 90px;
    margin: 1rem 0;
  }

  h5 {
    margin-bottom: 0;
    color: #3D3D3D;
    font-weight: $font-weight-light;

    &.bold {
      font-weight: $font-weight-semi-bold;
    }
  }
}