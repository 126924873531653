$font-family-base: "Hind", sans-serif;

// Colors
$gray: #7B7D80;
$gray-light: #9B9B9B;
$gray-dark: #5A5A5A;
$orange: #FF9900;
$dark-desaturated-blue: #024C77;
$green: #BBB834;
$gray-background: #F9F8F5;

$primary: $green;
$secondary: $dark-desaturated-blue;

$font-weight-light: 300;
$font-weight-semi-bold: 600;
$font-weight-bold: bold;

// Theme colors
$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);