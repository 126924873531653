@include media-breakpoint-up(md) {
  .colour-range {
    .title-with-divider__title {
      text-align: left;
      color: $green;
    }
  }

  .colour-range__tabs {
    background-color: $gray-background;
    padding: 2rem;
  }

  ul.nav-tabs {
    margin-bottom: 0 !important;
  }
}