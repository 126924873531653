.specs-card {
  background: $specs-card-background;
  box-shadow: 2px 2px 4px -2px rgba(0,0,0,0.50);
}

.specs-card__image {
  width: 100%;
  height: 0;
  padding-bottom: 70%;
  background-color: #f1f1f1;
  background-position: center center;
  background-size: cover;
}

.specs-card__title {
  font-size: $h5-font-size;
  padding: 1rem 1rem 0 1rem;
  color: $green;
  font-weight: $font-weight-semi-bold;
  margin: 0;
}

.specs-card__description {
  padding: 1rem;
  text-align: justify;
  color: $specs-card-color;
}