@include media-breakpoint-up(md) {
  .landing-hero {
    padding-top: $header-height;

    > .container {
      > .row {
        min-height: 500px;
        height: 60vh;
      }
    }
  }

  .hero-stamp {
    img {
      width: 280px;
    }
  }

  .hero-content__title {
    font-size: $h1-font-size * 1.2;
  }

  .floating-arrow {
    display: none;
  }
}