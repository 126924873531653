@include media-breakpoint-up(lg) {
  .colour-card {
    border: none;
  }

  .colour-card__image {
    padding-bottom: 75%;
  }

  .colour-card__description {
    padding: 1rem 0;

    h6 {
      font-weight: $font-weight-semi-bold;
    }
  }
}