.maglia-header {
  height: $header-height;
  text-align: left;
  position: relative;
  z-index: 1;
}

.maglia-header-logo {
  height: $header-logo-height;
  margin: (($header-height - $header-logo-height) * .5) 0;
}