.title-with-divider {

}

.title-with-divider__title {
  text-align: center;
  text-transform: uppercase;
}

.title-with-divider__title--black {
  color: black;
}

.title-with-divider__title--gray {
  color: #88888A;
}

.title-with-divider__divider {
  width: 30%;
}