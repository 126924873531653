@include media-breakpoint-down(sm) {
  .landing-hero {
    height: 100vh;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.18);
      z-index: 0;
    }

    > .container {
      position: relative;
      z-index: 2;
    }
  }

  .hero-stamp {
    height: 45vh;
    padding-top: $header-height;

    img {
      width: 36vw;
    }
  }

  .hero-content-wrapper {
    height: 50vh;
  }

  .hero-content__title {
    font-size: $h2-font-size;
    font-weight: bold;
    margin-bottom: .5rem;
  }

  .hero-content__subtitle {
    font-size: $h5-font-size;
  }
}