.colour-range {
  .nav-tabs {
    border-color: $gray-background;

    .nav-item {
      .nav-link {
        color: $black;
        background-color: transparent;
        font-weight: $font-weight-semi-bold;
        border-color: darken($gray-background, 10%);
        border-bottom-color: transparent;

        &.active {
          background-color: $gray-background;
          border-color: $gray-background;
          color: $green;
          outline: none;
          box-shadow: none;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:hover {
          color: $green;
        }

        &:first-child {
          margin-right: 5px;
        }
      }
    }
  }
}

.colour-cards {
  position: relative;  // to position SelectedColor component

  > .row {
    margin-left: $colour-cards-gutter-width * -.5;
    margin-right: $colour-cards-gutter-width * -.5;

    > [class*="col"] {
      padding-left: $colour-cards-gutter-width / 2;
      padding-right: $colour-cards-gutter-width / 2;
      margin-bottom: $colour-cards-gutter-width;
    }
  }
}