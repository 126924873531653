.landing-hero {
  background: url("../img/background-v2.jpg") no-repeat center center;
  background-size: cover;
  position: relative;
  color: white;
  margin-top: $header-height * -1;
}

.hero-stamp {

}

.hero-content-wrapper {

}

.hero-content__title,
.hero-content__subtitle {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.50);
}

.hero-content__title {
  margin-bottom: 1rem;
  font-weight: bold;
}

.hero-content__subtitle {
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.hero-content__button {

}

.floating-arrow {
  padding-top: 2rem;
}
