@include media-breakpoint-down(sm) {
  .home-color-request {
    > .container {
      > .row {
        > [class*="col"] {
          &:first-child {
            margin-bottom: 2rem;
          }

          &:last-child {
           background-color: $gray-background;
          }
        }
      }
    }
  }
}