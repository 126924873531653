@include media-breakpoint-down(sm) {
  .colour-range {
    margin-top: -42px;

    .nav-tabs {
      .nav-item {
        width: 50%;

        .nav-link {
          width: 100%;
        }
      }
    }
  }
}